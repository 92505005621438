exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-features-js": () => import("./../../../src/pages/allFeatures.js" /* webpackChunkName: "component---src-pages-all-features-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-downloads-page-js": () => import("./../../../src/pages/downloadsPage.js" /* webpackChunkName: "component---src-pages-downloads-page-js" */),
  "component---src-pages-eula-js": () => import("./../../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-extended-faq-js": () => import("./../../../src/pages/extendedFaq.js" /* webpackChunkName: "component---src-pages-extended-faq-js" */),
  "component---src-pages-extended-pricing-plans-js": () => import("./../../../src/pages/extendedPricingPlans.js" /* webpackChunkName: "component---src-pages-extended-pricing-plans-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kvkk-js": () => import("./../../../src/pages/kvkk.js" /* webpackChunkName: "component---src-pages-kvkk-js" */),
  "component---src-pages-prime-api-js": () => import("./../../../src/pages/primeApi.js" /* webpackChunkName: "component---src-pages-prime-api-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */)
}

